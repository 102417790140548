import * as React from "react";
import { nanoid } from "nanoid";
import { DataContext } from "../components/Database";
import { isReady } from "../services/dbController";
import { useCategories } from "./data";
import { usePrimaryLanguage } from "../hooks/settings";
import useConversationInterface from "../hooks/useOverviewInterface";

const LOADING = 0,
  SUCCESS = 1,
  FAIL = 2;

export function createConversation(
  collection,
  options: {
    // title: string;
    callback: (id: string) => void;
  },
) {
  const newId = nanoid();
  // Create a new entry
  const doc = collection.insert({
    id: newId,
    title: "",
    categories: [],
    languages: [],
    bubbles: [],
    thread: [],
  });

  if (options.callback) {
    options.callback(newId);
  }
}

export function useConversation(id?: string) {
  const [primaryLanguage] = usePrimaryLanguage();
  const { db } = React.useContext(DataContext);
  const interfaceLabels = useConversationInterface(primaryLanguage);

  if (!isReady(db) || typeof id === "undefined") {
    return {};
  }

  const collection = db.getCollection("conversation");
  const doc = collection.findOne({ id });

  function push(
    speaker: "A" | "B",
    sentences: { id: number; [key: string]: any }[],
    starter?: { id: number; [key: string]: any },
  ) {
    doc.bubbles = [
      ...doc.bubbles,
      ...sentences.map((sentence) => ({
        ...sentence,
        speaker,
      })),
    ];
    const isStarter = !!starter;
    if (starter) {
      doc.thread = [
        ...doc.thread,
        {
          id: isStarter ? starter.id : sentences[0].id,
          type: isStarter ? "starter" : "sentence",
          speaker,
        },
      ];
    }
    collection.update(doc);
  }

  function editTitle(value: string) {
    doc.title = value;
    collection.update(doc);
  }

  if (doc) {
    return {
      conversation: doc,
      push,
      editTitle,
    };
  } else {
    return {
      error: "Not found", // TODO:
    };
  }
  // setConversation({
  //   id: "ojs4",
  //   languageA: "UK",
  //   languageB: "DE",
  //   bubbles: [
  //     {
  //       id: 2,
  //       speaker: "A",
  //       t: {
  //         DE: "Ich hatte einen Unfall.",
  //         UK: "Я потрапив в аварію.",
  //       },
  //     },
  //     {
  //       id: 4,
  //       speaker: "A",
  //       t: {
  //         DE: "Ich muss zum Krankenhaus.",
  //         UK: "Я маю йти в лікарню.",
  //       },
  //     },
  //     {
  //       id: 4,
  //       speaker: "A",
  //       t: {
  //         DE: "Ich muss zum Krankenhaus.",
  //         UK: "Я маю йти в лікарню.",
  //       },
  //     },
  //     {
  //       id: 8,
  //       speaker: "B",
  //       t: {
  //         DE: "Ich zeig den Weg",
  //         UK: "Я лікарню в йти.",
  //       },
  //     },
  //     {
  //       id: 4,
  //       speaker: "A",
  //       t: {
  //         DE: "Ich muss zum Krankenhaus.",
  //         UK: "Я маю йти в лікарню.",
  //       },
  //     },
  //   ],
  //   thread: [
  //     {
  //       type: "sentence",
  //       id: 2,
  //       speaker: "A",
  //     },
  //     {
  //       type: "sentence",
  //       id: 4,
  //       speaker: "A",
  //     },
  //     {
  //       type: "starter",
  //       id: 7,
  //       speaker: "B",
  //     },
  //   ],
  // });
}

function compareMetaUpdatedReverse(a, b) {
  const v1 = a.meta.updated || a.meta.created,
    v2 = b.meta.updated || b.meta.created;
  if (v1 < v2) {
    return 1; // normally -1, but this is reversed
  }
  if (v1 > v2) {
    return -1; // normally 1, but this is reversed
  }
  return 0;
}

export function useConversations() {
  const { db } = React.useContext(DataContext);
  const [primaryLanguage] = usePrimaryLanguage();
  const categories = useCategories();
  const categoriesById = (categories || []).reduce(
    (acc, category) => ({
      ...acc,
      [category.id]: category,
    }),
    {},
  );
  if (isReady(db)) {
    const collection = db.getCollection("conversation");
    return (collection.find().sort(compareMetaUpdatedReverse) || []).map(
      (conversation) => ({
        ...conversation,
        categories: conversation.categories
          .map((catId) => categoriesById[catId]?.t[primaryLanguage] || null)
          .filter((c) => !!c),
      }),
    );
  }
  return [];
}
