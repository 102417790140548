import { useStaticQuery, graphql } from "gatsby";
import { filterLanguage, language } from "./_helpers";

export default function useOverviewInterface(language: language | language[]) {
  const result = useStaticQuery(graphql`
    query UseOverviewInterface {
      allInterface {
        edges {
          node {
            _language
            allConversations
            startConversation
            untitled
          }
        }
      }
    }
  `);
  return filterLanguage(result, language || "UK");
}
