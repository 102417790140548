import tw, { styled } from "twin.macro";

const Fab = styled.button(({ size = "md" }) => [
  tw`bg-primary text-on-primary min-w-min flex whitespace-nowrap justify-center items-center shadow drop-shadow-2xl text-label-lg`,
  //   tw`flex items-center justify-center gap-1 outline-none uppercase tracking-wider focus:outline-none transition-all duration-300 rounded-lg py-2.5 px-6 leading-normal text-white `,
  //   hasIcon && tw`text-`,
  // position === "br" && tw`right-3.5 bottom-3.5`,
  // position === "bl" && tw`left-3.5 bottom-3.5`,
  size === "md" && tw`w-7 h-7 rounded-2 p-2 space-x-1`,
  size === "lg" && tw`w-12 h-12 rounded-3.5 p-3.5 space-x-3.5`,
]);

Fab.Text = tw.div`mt-[1px]`;

export default Fab;
