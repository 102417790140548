import * as React from "react";
import tw, { styled } from "twin.macro";

const TopAppBar = tw.div`flex items-center py-1`;

TopAppBar.Icon = tw.button`text-title-lg w-6 h-6 flex justify-center items-center rounded-1`;

TopAppBar.Title = styled.div(({ align = "left" }) => [
  tw`text-title-lg ml-1 flex-1`,
  align === "center" && tw`text-center mr-8`,
]);

export default TopAppBar;
