import * as React from "react";
import { GlobalStyles } from "twin.macro";

const Layout = ({ children }) => (
  <React.Fragment>
    <GlobalStyles />
    {children}
  </React.Fragment>
);

export default Layout;
